import { categories } from "./constant";

const returnParentCategory = (name: string) => {
  for (let k = 0; k < 3; k++) {
    for (let j = 0; j < categories[k].subCategories.length; j++) {
      if (categories[k].subCategories[j].name === name) {
        return categories[k].name;
      }
    }
  }
};

const returnCategoryName = (initial: string) => {
  if (initial === "BU") {
    return "Baseketball Uniforms";
  } else if (initial === "BaseU") {
    return "Baseball Uniforms";
  } else if (initial === "GU") {
    return "Goalkeeper Uniforms";
  } else if (initial === "SU") {
    return "Soccer Uniforms";
  } else if (initial === "VU") {
    return "Volleyball Uniforms";
  } else if (initial === "AU") {
    return "Afl Uniforms";
  } else if (initial === "IH") {
    return "Ice Hockey";
  } else if (initial === "CU") {
    return "Cricket Uniforms";
  } else if (initial === "LU") {
    return "Lacrosse Uniforms";
  } else if (initial === "RU") {
    return "Rugby Uniforms";
  } else if (initial === "PJackets") {
    return "Puffer Jackets";
  } else if (initial === "CTS") {
    return "Casual T - Shirts";
  } else if (initial === "Hoodies") {
    return "Hoodies";
  } else if (initial === "PS") {
    return "Polo Shirt";
  } else if (initial === "SS") {
    return "Sweat Shirts";
  } else if (initial === "WP") {
    return "Wool Cap";
  } else if (initial === "Leggings") {
    return "Leggings";
  } else if (initial === "SB") {
    return "Sports bra";
  } else if (initial === "BNetBall") {
    return "Base Netball";
  } else if (initial === "Jersey") {
    return "Jersey";
  } else if (initial === "Shorts") {
    return "Shorts";
  } else if (initial === "LSleeve") {
    return "Long Sleeve";
  } else if (initial === "ShSleeve") {
    return "Short Sleeve";
  } else if (initial === "Singlets") {
    return "Singlets";
  } else if (initial === "TSuit") {
    return "Track Suit";
  } else {
    return "";
  }
};

const isValidPath = (initial: string, categ: string) => {
  if (initial === "BU" && categ === "SW") {
    return true;
  } else if (initial === "BaseU" && categ === "SW") {
    return true;
  } else if (initial === "GU" && categ === "SW") {
    return true;
  } else if (initial === "SU" && categ === "SW") {
    return true;
  } else if (initial === "VU" && categ === "SW") {
    return true;
  } else if (initial === "AU" && categ === "SW") {
    return true;
  } else if (initial === "IH" && categ === "SW") {
    return true;
  } else if (initial === "CU" && categ === "SW") {
    return true;
  } else if (initial === "LU" && categ === "SW") {
    return true;
  } else if (initial === "RU" && categ === "SW") {
    return true;
  } else if (initial === "PJackets" && categ === "CW") {
    return true;
  } else if (initial === "CTS" && categ === "CW") {
    return true;
  } else if (initial === "Hoodies" && categ === "CW") {
    return true;
  } else if (initial === "PS" && categ === "CW") {
    return true;
  } else if (initial === "SS" && categ === "CW") {
    return true;
  } else if (initial === "WP" && categ === "CW") {
    return true;
  } else if (initial === "Leggings" && categ === "CPW") {
    return true;
  } else if (initial === "SB" && categ === "CPW") {
    return true;
  } else if (initial === "BNetBall" && categ === "SBW") {
    return true;
  } else if (initial === "Hoodies" && categ === "SBW") {
    return true;
  } else if (initial === "Jersey" && categ === "SBW") {
    return true;
  } else if (initial === "Shorts" && categ === "SBW") {
    return true;
  } else if (initial === "Leggings" && categ === "SBW") {
    return true;
  } else if (initial === "LSleeve" && categ === "SBW") {
    return true;
  } else if (initial === "ShSleeve" && categ === "SBW") {
    return true;
  } else if (initial === "Singlets" && categ === "SBW") {
    return true;
  } else if (initial === "TSuit" && categ === "SBW") {
    return true;
  } else {
    return false;
  }
};

const returnCategoryInitial = (initial: string) => {
  if (initial === "Baseketball Uniforms") {
    return "BU";
  } else if (initial === "Baseball Uniforms") {
    return "BaseU";
  } else if (initial === "Goalkeeper Uniforms") {
    return "GU";
  } else if (initial === "Soccer Uniforms") {
    return "SU";
  } else if (initial === "Volleyball Uniforms") {
    return "VU";
  } else if (initial === "Afl Uniforms") {
    return "AU";
  } else if (initial === "Ice Hockey") {
    return "IH";
  } else if (initial === "Cricket Uniforms") {
    return "CU";
  } else if (initial === "Lacrosse Uniforms") {
    return "LU";
  } else if (initial === "Rugby Uniforms") {
    return "RU";
  } else if (initial === "Puffer Jackets") {
    return "PJackets";
  } else if (initial === "Casual T - Shirts") {
    return "CTS";
  } else if (initial === "Hoodies") {
    return "Hoodies";
  } else if (initial === "Polo Shirt") {
    return "PS";
  } else if (initial === "Sweat Shirts") {
    return "SS";
  } else if (initial === "Wool Cap") {
    return "WP";
  } else if (initial === "Leggings") {
    return "Leggings";
  } else if (initial === "Sports bra") {
    return "SB";
  } else if (initial === "Base Netball") {
    return "BNetBall";
  } else if (initial === "Jersey") {
    return "Jersey";
  } else if (initial === "Shorts") {
    return "Shorts";
  } else if (initial === "Long Sleeve") {
    return "LSleeve";
  } else if (initial === "Short Sleeve") {
    return "ShSleeve";
  } else if (initial === "Singlets") {
    return "Singlets";
  } else if (initial === "Track Suit") {
    return "TSuit";
  } else {
    return "";
  }
};

export {
  returnParentCategory,
  returnCategoryName,
  isValidPath,
  returnCategoryInitial,
};
