import classNames from "classnames";
import { useEffect, useState } from "react";
import { X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { setOrders } from "shared/redux/reducers/orderSlice";
import { CoverURL } from "shared/utils/endpoints";
import styles from "./style.module.scss";
const CartProduct = ({ item, index }: any) => {
  const dipatch = useDispatch();
  const [quantity, setQuantity] = useState<number>(item?.quantity);
  const { orders } = useSelector((state: any) => state.root);
  const handleRemove = () => {
    let filterOrders = orders?.orders.filter((itm: any) => {
      return itm?.product?._id !== item?.product?._id;
    });
    dipatch(setOrders({ orders: filterOrders }));
  };

  const handleAdd = (qnty: any) => {
    if (Number(quantity) > 0) {
      let filterOrders = orders?.orders.filter((itm: any) => {
        return itm?.product?._id !== item?.product?._id;
      });

      let resp = {
        product: item?.product,
        quantity: Number(qnty),
      };
      filterOrders.push(resp);

      dipatch(setOrders({ orders: filterOrders }));
    } else {
      handleRemove();
    }
  };

  useEffect(() => {
    setQuantity(item?.quantity);
  }, [item?.product?.id]);

  return (
    <>
      <div
        className={classNames(
          "d-flex align-items-start w-100 py-3",
          styles.brBtm,
          styles.h100
        )}
      >
        <div
          className={classNames(
            "col-4 d-flex align-items-center justify-content-start"
          )}
        >
          <X
            color="black"
            role={"button"}
            onClick={(e) => {
              e.stopPropagation();
              handleRemove();
            }}
          />
          <img
            src={item?.product?.cover ? CoverURL + item?.product?.cover : ""}
            className={classNames(styles.imgStyle, "ms-2")}
          />
        </div>
        <div
          className={classNames(
            "col-4 d-flex align-items-center justify-content-start",
            styles.h100
          )}
        >
          <label className={classNames(styles.titleLabel, "pe-2")}>
            {item?.product?.title}
          </label>
        </div>
        <div
          className={classNames(
            "col-4 d-flex align-items-center justify-content-start",
            styles.h100
          )}
        >
          <input
            type={"number"}
            className={classNames(styles.numField)}
            defaultValue={1}
            value={quantity}
            onChange={(e: any) => {
              setQuantity(e.target.value);
              handleAdd(e.target.value);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default CartProduct;
