import { ReactComponent as LeftArrowIcon } from "./icons/left-arrow.svg";
import { ReactComponent as RightArrowIcon } from "./icons/right-arrow.svg";
import { ReactComponent as LogoIcon } from "./icons/logo.svg";
import { ReactComponent as FacebookIcon } from "./icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "./icons/instagram.svg";
import { ReactComponent as WhatsappIcon } from "./icons/whatsapp.svg";
import CommingSoonAnimation from "./animations/coming-soon.json";
const FitnessBanner = require("./images/Fitness-Wear-Banner.jpg");
const SportsBanner = require("./images/Sports-Wear-Banner.jpeg");
const FeatureFashion = require("./images/FASHION-Hoodie-Category-Banner.jpg");
const FeatureJackets = require("./images/fashion-jackets.jpeg");
const FeatureSoccer = require("./images/Soccer-Footabll-Uniform-Category-Banner.jpg");
const AboutUsImg = require("./images/about-us.jpeg");
export {
  LeftArrowIcon,
  RightArrowIcon,
  FitnessBanner,
  SportsBanner,
  LogoIcon,
  FeatureFashion,
  FeatureSoccer,
  FeatureJackets,
  FacebookIcon,
  InstagramIcon,
  AboutUsImg,
  WhatsappIcon,
  CommingSoonAnimation,
};
