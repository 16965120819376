import * as yup from "yup";

const OrderVS = yup.object().shape({
  firstname: yup.string().required("First Name is Required").label("firstname"),
  lastname: yup.string().required("Last Name is Required").label("lastname"),
  phonenumber: yup
    .string()
    .required("Phonenumber is Required")
    .label("phonenumber"),
  email: yup.string().required("Email is Required").label("email").email(),
});

export { OrderVS };
