const categories: {
  name: string;
  subCategories: {
    name: string;
    route: string;
  }[];
  route: string;
}[] = [
  {
    name: "Sports Wear",
    subCategories: [
      {
        name: "Baseketball Uniforms",
        route: "/product-category/SW/BU",
      },
      {
        name: "Baseball Uniforms",
        route: "/product-category/SW/BaseU",
      },
      {
        name: "Goalkeeper Uniforms",
        route: "/product-category/SW/GU",
      },
      {
        name: "Soccer Uniforms",
        route: "/product-category/SW/SU",
      },
      {
        name: "Volleyball Uniforms",
        route: "/product-category/SW/VU",
      },
      {
        name: "Afl Uniforms",
        route: "/product-category/SW/AU",
      },
      {
        name: "Ice Hockey",
        route: "/product-category/SW/IH",
      },
      {
        name: "Cricket Uniforms",
        route: "/product-category/SW/CU",
      },
      {
        name: "Lacrosse Uniforms",
        route: "/product-category/SW/LU",
      },
      {
        name: "Rugby Uniforms",
        route: "/product-category/SW/RU",
      },
    ],
    route: "/product-category/SW",
  },
  {
    name: "Casual Wear",
    subCategories: [
      {
        name: "Puffer Jackets",
        route: "/product-category/CW/PJackets",
      },
      {
        name: "Casual T - Shirts",
        route: "/product-category/CW/CTS",
      },
      {
        name: "Hoodies",
        route: "/product-category/CW/Hoodies",
      },
      {
        name: "Polo Shirt",
        route: "/product-category/CW/PS",
      },
      {
        name: "Sweat Shirts",
        route: "/product-category/CW/SS",
      },
      {
        name: "Wool Cap",
        route: "/product-category/CW/WP",
      },
    ],
    route: "/product-category/CW",
  },
  {
    name: "Compression Wear",
    subCategories: [
      {
        name: "Leggings",
        route: "/product-category/CPW/Leggings",
      },
      {
        name: "Sports bra",
        route: "/product-category/CPW/SB",
      },
    ],
    route: "/product-category/CPW",
  },
  {
    name: "Sublimation Wear",
    subCategories: [
      {
        name: "Base Netball",
        route: "/product-category/SBW/BNetBall",
      },
      {
        name: "Hoodies",
        route: "/product-category/SBW/Hoodies",
      },
      {
        name: "Jersey",
        route: "/product-category/SBW/Jersey",
      },
      {
        name: "Shorts",
        route: "/product-category/SBW/Shorts",
      },
      {
        name: "Leggings",
        route: "/product-category/SBW/Leggings",
      },
      {
        name: "Long Sleeve",
        route: "/product-category/SBW/LSleeve",
      },
      {
        name: "Short Sleeve",
        route: "/product-category/SBW/ShSleeve",
      },
      {
        name: "Singlets",
        route: "/product-category/SBW/Singlets",
      },
      {
        name: "Track Suit",
        route: "/product-category/SBW/TSuit",
      },
    ],
    route: "/product-category/SBW",
  },
];

export { categories };
