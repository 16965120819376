import classNames from "classnames";
import { Mail, MapPin, Phone } from "react-feather";
import styles from "./style.module.scss";
const Contact = () => {
  return (
    <div
      className={classNames(
        styles.customContainer,
        "d-flex flex-column flex-lg-row"
      )}
    >
      <div className="col-lg-6 col-12 d-flex flex-column align-items-lg-start align-items-center">
        <label className={classNames(styles.title, "mt-4 mb-2")}>
          Contact <label style={{ color: "black" }}>Us</label>
        </label>
        <label className={classNames(styles.subtitle)}>Customer Support</label>
        <label className={classNames(styles.listItem, "mt-3")}>
          <Phone className={classNames(styles.listIcon)} /> +92 309 0009101
        </label>
        <label className={classNames(styles.listItem, "mt-3")}>
          <Mail className={classNames(styles.listIcon)} />{" "}
          silverskyapparel@gmail.com
        </label>
        <label className={classNames(styles.listItem, "mt-3")}>
          <MapPin className={classNames(styles.listIcon)} /> Rasool pura
          Sambriyal, Sialkot, 51070
        </label>
      </div>
      <div className="col-lg-6 col-12 d-flex flex-column align-items-lg-start align-items-center p-5">
        <label className={classNames(styles.subtitle)}>Inquiries</label>
        <input
          type={"text"}
          className={classNames(styles.inputContainer, "mt-4")}
          placeholder={"Your Name"}
        />
        <input
          type={"email"}
          className={classNames(styles.inputContainer, "mt-4")}
          placeholder={"Your Email Address"}
        />
        <textarea
          rows={4}
          className={classNames(styles.inputContainer, "mt-4")}
          placeholder={"Message"}
        />
        <button className={classNames(styles.btnStyle, "mt-4")}>Submit</button>
      </div>
    </div>
  );
};

export default Contact;
