import { Spinner } from "react-bootstrap";
import styles from "./style.module.scss";

interface BtnProps extends React.HTMLProps<HTMLButtonElement> {
  title: string;
  Icon: any;
  containerStyle: any;
  iconStyle: any;
  isDisable: boolean;
  loading: boolean;
}

const CustomButton = ({
  title,
  Icon,
  containerStyle,
  iconStyle,
  isDisable,
  onClick,
  loading,
}: Partial<BtnProps>) => {
  return (
    <button
      className={` ${
        containerStyle ? containerStyle : styles.customBtnContainer
      }`}
      onClick={onClick}
      disabled={isDisable}
    >
      {" "}
      {loading ? (
        <Spinner size="sm" animation="border" style={{ color: "white" }} />
      ) : (
        <>
          {Icon && <Icon className={iconStyle && iconStyle} />}
          {title && title}
        </>
      )}
    </button>
  );
};

export default CustomButton;
