import { HTTP_CLIENT } from "../utils/interceptor";

export const GetFeatureProducts = () => {
  return HTTP_CLIENT.get("products/feature");
};
export const GetSingleProduct = (id: any) => {
  return HTTP_CLIENT.get(`products/${id}`);
};
export const GetSuggestion = (id: any, categ: any, skip: any) => {
  return HTTP_CLIENT.get(`products/category/${id}/${categ}/${skip}`);
};
export const GetAllProducts = (skip: any) => {
  return HTTP_CLIENT.get(`products/paginate/${skip}`);
};
export const GetCategoryAllProducts = (skip: any, categ: any) => {
  return HTTP_CLIENT.get(`products/paginate/${categ}/${skip}`);
};
export const GetSubCategoryAllProducts = (obj: any) => {
  return HTTP_CLIENT.post(`products/allProducts/`, obj);
};
