import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { publicRoute, routeConstant } from "./allRoutes";
import Layout from "./layout";

function AuthRoute() {
  return (
    <>
      <Routes>
        {publicRoute.map((route, inx) => {
          return (
            <React.Fragment key={inx}>
              <Route
                path={route.path}
                key={inx}
                element={<Layout {...route} />}
              />
              <Route
                path={"*"}
                element={<Navigate to={routeConstant.home.path} replace />}
              />
            </React.Fragment>
          );
        })}
      </Routes>
    </>
  );
}

export default AuthRoute;
