import { FitnessBanner, SportsBanner } from "assets";
import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import CustomSlider from "shared/components/customSlider";
import FeaturedCategories from "shared/components/featuredCategories";
import ProductCard from "shared/components/productCard";
import { GetFeatureProducts } from "shared/services/productServics";
import styles from "./style.module.scss";

function Home() {
  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<any>([]);
  const value = useMemo(
    () => [
      {
        thumbnail: SportsBanner,
      },
      {
        thumbnail: FitnessBanner,
      },
    ],
    []
  );

  const handleFeatureProducts = () => {
    GetFeatureProducts()
      .then(({ data }) => {
        setProducts(data);
      })
      .catch((err) => {
        console.log("ERR", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    handleFeatureProducts();
  }, []);

  return (
    <div className={classNames("p-0 w-100")}>
      <CustomSlider featured={value} />
      <FeaturedCategories />
      <div className={classNames(styles.titleContainer, "mt-5")}>
        <label className={classNames(styles.title)}>Featured Products</label>
      </div>
      <div className={classNames("px-3 mt-4")}>
        <div
          className={`d-flex row justify-content-sm-between  justify-content-center   align-items-center`}
        >
          {loading ? (
            <div
              className={classNames(
                "mb-4 d-flex justify-content-center align-items-center",
                styles.minHeight
              )}
            >
              <Spinner animation="border" />
            </div>
          ) : (
            <>
              {products?.map((item: any, inx: any) => {
                return <ProductCard key={inx} item={item} />;
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(Home);
