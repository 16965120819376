import { LogoIcon } from "assets";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { ChevronDown, ChevronRight, Menu, ShoppingCart } from "react-feather";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { routeConstant } from "shared/routes/routeConstant";
import {
  casualCategories,
  compressionCategories,
  sportsCategories,
  sublimationCategories,
} from "./constant";
import styles from "./style.module.scss";

interface headerProps {
  isSideCanvas: boolean;
  setIsSideCanvas: (val: boolean) => void;
  setIsShoppingCartOpen: (val: boolean) => void;
}

const Header = ({ setIsSideCanvas, setIsShoppingCartOpen }: headerProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { orders } = useSelector((state: any) => state.root);
  const [activeTab, setActiveTab] = useState<string>("");
  const [mouseIn, setMouseIn] = useState<boolean>(false);
  const mouseOver = useRef<boolean>(false);
  useEffect(() => {
    if (location.pathname.includes("about")) {
      setActiveTab(routeConstant.about.title);
    } else if (location.pathname.includes("product")) {
      setActiveTab(routeConstant.products.title);
    } else if (location.pathname.includes("contact")) {
      setActiveTab(routeConstant.contact.title);
    } else if (location.pathname.includes("cart")) {
      setActiveTab("");
    } else if (location.pathname.includes("checkout")) {
      setActiveTab("");
    } else {
      setActiveTab(routeConstant.home.title);
    }
  }, [location.pathname]);

  return (
    <div
      className={classNames(
        styles.headerContainer,
        "d-flex justify-content-center align-items-center w-100 px-4 px-sm-0"
      )}
    >
      <div
        className={classNames(
          styles.customContainer,
          "d-flex justify-content-between align-items-center w-100"
        )}
      >
        <div
          onClick={() => {
            setActiveTab(routeConstant.home.title);
            navigate(routeConstant.home.path);
          }}
          role="button"
        >
          <LogoIcon className={classNames(styles.logoIcon)} />
        </div>
        <div
          className={classNames(
            "d-flex justify-content-center align-items-center gap-4"
          )}
        >
          <div
            className={classNames(
              "d-lg-flex d-none justify-content-center align-items-center gap-4"
            )}
          >
            <label
              className={classNames(
                styles.navlabel,
                activeTab === routeConstant?.home.title && styles.activeNav
              )}
              onClick={() => {
                setActiveTab(routeConstant.home.title);
                navigate(routeConstant.home.path);
              }}
            >
              Home
            </label>
            <div
              className={classNames("position-relative")}
              onMouseEnter={() => {
                setMouseIn(true);
              }}
              onMouseLeave={() => {
                if (mouseIn) {
                  let tme = setTimeout(() => {
                    if (!mouseOver.current) {
                      setMouseIn(false);
                    } else {
                      clearTimeout(tme);
                    }
                  }, 800);
                }
              }}
            >
              <label
                className={classNames(
                  styles.navlabel,
                  activeTab === routeConstant?.products.title &&
                    styles.activeNav
                )}
                onClick={() => {
                  setActiveTab(routeConstant.products.title);
                  navigate(routeConstant.products.path);
                }}
              >
                Products
                <ChevronDown height={22} width={22} />
              </label>
              {mouseIn && (
                <DropDownCategories
                  setMouseIn={setMouseIn}
                  mouseOver={mouseOver}
                />
              )}
            </div>

            <label
              className={classNames(
                styles.navlabel,
                activeTab === routeConstant?.about.title && styles.activeNav
              )}
              onClick={() => {
                setActiveTab(routeConstant.about.title);
                navigate(routeConstant.about.path);
              }}
            >
              About
            </label>
            <label
              className={classNames(
                styles.navlabel,
                activeTab === routeConstant?.contact.title && styles.activeNav
              )}
              onClick={() => {
                setActiveTab(routeConstant.contact.title);
                navigate(routeConstant.contact.path);
              }}
            >
              Contact
            </label>
          </div>
          <div className={classNames("d-flex d-lg-none")}>
            <Menu
              className={classNames(styles.cartIcon)}
              color="white"
              role={"button"}
              onClick={() => {
                setIsSideCanvas(true);
              }}
            />
          </div>
          <div
            className={classNames(
              "position-relative d-flex justify-content-center align-items-center"
            )}
            role={"button"}
            onClick={() => {
              setIsShoppingCartOpen(true);
            }}
          >
            <ShoppingCart
              className={classNames(styles.cartIcon)}
              color="white"
            />
            <div
              className={classNames(styles.countCont)}
              role={"button"}
              onClick={() => {
                setIsShoppingCartOpen(true);
              }}
            >
              <label className={classNames(styles.countLabel)} role={"button"}>
                {orders?.orders?.length > 9 ? "9+" : orders?.orders?.length}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface DropDownProps {
  setMouseIn: (val: boolean) => void;
  mouseOver: any;
}

const DropDownCategories = ({ setMouseIn, mouseOver }: DropDownProps) => {
  const navigate = useNavigate();
  const [mouseIn1, setMouseIn1] = useState<boolean>(false);
  const [mouseIn2, setMouseIn2] = useState<boolean>(false);
  const [mouseIn3, setMouseIn3] = useState<boolean>(false);
  const [mouseIn4, setMouseIn4] = useState<boolean>(false);
  const mouseOver1 = useRef<boolean>(false);
  const mouseOver2 = useRef<boolean>(false);
  const mouseOver3 = useRef<boolean>(false);
  const mouseOver4 = useRef<boolean>(false);
  return (
    <div
      className={classNames(styles.dropDownContainer)}
      onMouseEnter={() => {
        setMouseIn(true);
      }}
      onMouseLeave={() => {
        setMouseIn(false);
        mouseOver.current = false;
      }}
      onMouseOver={() => {
        mouseOver.current = true;
      }}
    >
      <div
        className={classNames(styles.categCont, "px-3")}
        onMouseOver={() => {
          setMouseIn1(true);
          setMouseIn2(false);
          setMouseIn3(false);
          setMouseIn4(false);
        }}
        onMouseLeave={() => {
          if (mouseIn1) {
            let tme = setTimeout(() => {
              if (!mouseOver1.current) {
                setMouseIn1(false);
              } else {
                clearTimeout(tme);
              }
            }, 800);
          }
        }}
        onClick={() => {
          navigate("/product-category/SW");
        }}
      >
        <label className={classNames(styles.categTitle)}>
          Sports Wear <ChevronRight color="white" height={15} width={15} />
        </label>
        {mouseIn1 && (
          <div
            className={classNames(styles.subCateg1)}
            onMouseEnter={() => {
              setMouseIn1(true);
            }}
            onMouseOver={() => {
              mouseOver1.current = true;
            }}
            onMouseLeave={() => {
              setMouseIn1(false);
              mouseOver1.current = false;
            }}
          >
            {sportsCategories.map((item, ind) => {
              return (
                <div
                  className={classNames(styles.categCont, "px-3")}
                  key={ind}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(item?.route);
                  }}
                >
                  <label className={classNames(styles.categTitle)}>
                    {item?.name}
                  </label>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div
        className={classNames(styles.categCont, "px-3")}
        onMouseOver={() => {
          setMouseIn2(true);
          setMouseIn1(false);
          setMouseIn3(false);
          setMouseIn4(false);
        }}
        onMouseLeave={() => {
          if (mouseIn2) {
            let tme = setTimeout(() => {
              if (!mouseOver2.current) {
                setMouseIn2(false);
              } else {
                clearTimeout(tme);
              }
            }, 800);
          }
        }}
        onClick={() => {
          navigate("/product-category/CW");
        }}
      >
        <label className={classNames(styles.categTitle)}>
          Casual Wear <ChevronRight color="white" height={15} width={15} />
        </label>
        {mouseIn2 && (
          <div
            className={classNames(styles.subCateg1)}
            onMouseEnter={() => {
              setMouseIn2(true);
            }}
            onMouseOver={() => {
              mouseOver2.current = true;
            }}
            onMouseLeave={() => {
              setMouseIn1(false);
              mouseOver2.current = false;
            }}
          >
            {casualCategories.map((item, ind) => {
              return (
                <div
                  className={classNames(styles.categCont, "px-3")}
                  key={ind}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(item?.route);
                  }}
                >
                  <label className={classNames(styles.categTitle)}>
                    {item?.name}
                  </label>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div
        className={classNames(styles.categCont, "px-3")}
        onMouseOver={() => {
          setMouseIn3(true);
          setMouseIn1(false);
          setMouseIn2(false);
          setMouseIn4(false);
        }}
        onMouseLeave={() => {
          if (mouseIn2) {
            let tme = setTimeout(() => {
              if (!mouseOver3.current) {
                setMouseIn3(false);
              } else {
                clearTimeout(tme);
              }
            }, 800);
          }
        }}
        onClick={() => {
          navigate("/product-category/CPW");
        }}
      >
        <label className={classNames(styles.categTitle)}>
          Compression Wear <ChevronRight color="white" height={15} width={15} />
        </label>
        {mouseIn3 && (
          <div
            className={classNames(styles.subCateg1)}
            onMouseEnter={() => {
              setMouseIn3(true);
            }}
            onMouseOver={() => {
              mouseOver3.current = true;
            }}
            onMouseLeave={() => {
              setMouseIn1(false);
              mouseOver3.current = false;
            }}
          >
            {compressionCategories.map((item, ind) => {
              return (
                <div
                  className={classNames(styles.categCont, "px-3")}
                  key={ind}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(item?.route);
                  }}
                >
                  <label className={classNames(styles.categTitle)}>
                    {item?.name}
                  </label>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div
        className={classNames(styles.categCont, "px-3")}
        onMouseOver={() => {
          setMouseIn1(false);
          setMouseIn2(false);
          setMouseIn3(false);
          setMouseIn4(true);
        }}
        onMouseLeave={() => {
          if (mouseIn4) {
            let tme = setTimeout(() => {
              if (!mouseOver4.current) {
                setMouseIn4(false);
              } else {
                clearTimeout(tme);
              }
            }, 800);
          }
        }}
        onClick={() => {
          navigate("/product-category/SBW");
        }}
      >
        <label className={classNames(styles.categTitle)}>
          Sublimation Wear <ChevronRight color="white" height={15} width={15} />
        </label>
        {mouseIn4 && (
          <div
            className={classNames(styles.subCateg1)}
            onMouseEnter={() => {
              setMouseIn4(true);
            }}
            onMouseOver={() => {
              mouseOver4.current = true;
            }}
            onMouseLeave={() => {
              setMouseIn4(false);
              mouseOver4.current = false;
            }}
          >
            {sublimationCategories.map((item, ind) => {
              return (
                <div
                  className={classNames(styles.categCont, "px-3")}
                  key={ind}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(item?.route);
                  }}
                >
                  <label className={classNames(styles.categTitle)}>
                    {item?.name}
                  </label>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
