import { WhatsappIcon } from "assets";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import ReactWhatsapp from "react-whatsapp";
import Header from "shared/components/header";
import Footer from "../footer";
import ShoppingCartCanvas from "../shopingCartCanvas";
import SideCanvas from "../sideCanvas";
import styles from "./style.module.scss";

interface Props {
  children: any;
}

const NavWrapper = ({ children }: Partial<Props>) => {
  const location = useLocation();
  const [isSideCanvas, setIsSideCanvas] = useState<boolean>(false);
  const [isShoppingCartOpen, setIsShoppingCartOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const handleScrollShow = () => {
    let elem: any = document.getElementById("sideNav");
    elem?.addEventListener("mouseenter", () => {
      elem.classList.add(styles.showSideNavContainer);
    });
    elem?.addEventListener("mouseleave", () => {
      elem.classList.remove(styles.showSideNavContainer);
    });
  };
  useEffect(() => {
    handleScrollShow();
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location.pathname]);
  return (
    <div
      className={classNames(
        styles.topLevelContainer,
        "p-0 m-0 position-relative"
      )}
    >
      <SideCanvas
        isOpen={isSideCanvas}
        setIsOpen={setIsSideCanvas}
        loading={loading}
      />
      <ShoppingCartCanvas
        isOpen={isShoppingCartOpen}
        setIsOpen={setIsShoppingCartOpen}
      />
      <div className={classNames(styles.mainContainer)}>
        <div className={classNames(styles.iconContainer)}>
          <ReactWhatsapp number="923090009101" message="" element="big">
            <WhatsappIcon className={classNames(styles.icon)} />
          </ReactWhatsapp>
        </div>
        <Header
          isSideCanvas={isSideCanvas}
          setIsSideCanvas={setIsSideCanvas}
          setIsShoppingCartOpen={setIsShoppingCartOpen}
        />
        {children}

        <Footer />
      </div>
    </div>
  );
};

export default React.memo(NavWrapper);
