import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setOrders } from "shared/redux/reducers/orderSlice";
import { routeConstant } from "shared/routes/routeConstant";
import { CoverURL } from "shared/utils/endpoints";
import CustomButton from "../customButton";
import styles from "./style.module.scss";
import { toastMessage } from "../toast";

const ProductCard = ({ item, isInProduct }: any) => {
  const navigate = useNavigate();
  const dipatch = useDispatch();
  const { orders } = useSelector((state: any) => state.root);
  const handleAdd = () => {
    let filterOrders = orders?.orders.filter((itm: any) => {
      return itm?.product?._id !== item?._id;
    });
    let foundItem: any = orders?.orders.filter((itm: any) => {
      return itm?.product?._id === item?._id;
    });

    if (foundItem?.length > 0) {
      let resp = {
        product: item,
        quantity: foundItem[0]?.quantity + 1,
      };
      filterOrders.push(resp);
    } else {
      let resp = {
        product: item,
        quantity: 1,
      };
      filterOrders.push(resp);
    }
    toastMessage("success", "Added to Cart!");
    dipatch(setOrders({ orders: filterOrders }));
  };
  return (
    <div
      className={classNames(
        "d-flex flex-column col-lg-3 col-md-6 col-12 align-items-center mb-5"
      )}
      role="button"
      onClick={() => {
        navigate(routeConstant.product.path.replace(":id", item?._id));
      }}
    >
      <img
        src={item?.cover ? CoverURL + item?.cover : ""}
        className={classNames(styles.imgStyle, "mb-2")}
      />
      <label className={classNames(styles.title, "py-3")} role="button">
        {item?.title}
      </label>
      <CustomButton
        title="Add to quote"
        onClick={(e: any) => {
          e.stopPropagation();
          handleAdd();
        }}
        containerStyle={isInProduct && classNames(styles.btnStyle2)}
      />
    </div>
  );
};

export default ProductCard;
