import { AboutUsImg } from "assets";
import classNames from "classnames";
import styles from "./style.module.scss";
const About = () => {
  return (
    <>
      <div className={classNames(styles.aboutContainer)}>
        <div
          className={classNames(
            styles.customContainer,
            "d-flex justify-content-md-start justify-content-center w-100"
          )}
        >
          <label className={classNames(styles.title)}>
            About <label style={{ color: "white" }}>US</label>
          </label>
        </div>
      </div>
      <div
        className={classNames(
          styles.customContainer,
          "d-flex flex-column align-items-start mt-5"
        )}
      >
        <label className={classNames(styles.contenttitle)}>Who we are?</label>
        <label className={classNames(styles.contentSubtitle, "mt-3")}>
          Our Story
        </label>
        <label className={classNames(styles.contentText)}>
          Welcome to Silver Sky Apparel (Pvt) Ltd, where quality meets fashion.
          At Silver Sky Apparel, we believe that quality is the key to success,
          and we strive to maintain the highest standards of quality in all of
          our products.
        </label>
        <label className={classNames(styles.contentSubtitle, "mt-3")}>
          Our Quality Assurance Process:
        </label>
        <label className={classNames(styles.contentText)}>
          We understand that quality is critical in the sports and leisure
          apparel industry, where comfort, durability, and style are essential
          factors. Silver Sky Apparel has a comprehensive quality assurance
          process that ensures every product we produce meets our high
          standards.
        </label>
        <label className={classNames(styles.contentText, "mt-1")}>
          Our quality assurance process begins with the selection of raw
          materials. We source only the best quality materials from reputable
          suppliers. Our materials undergo rigorous testing to ensure that they
          meet our quality standards before they are used in the production
          process.
        </label>
        <label className={classNames(styles.contentText, "mt-1")}>
          Our production process is closely monitored to ensure that every
          garment meets our quality standards. Our team of experienced
          professionals uses the latest technology and equipment to produce
          high-quality sportswear and leisure apparel that is comfortable,
          durable, and stylish.
        </label>
        <label className={classNames(styles.contentText, "mt-1")}>
          Once the production process is complete, our products undergo a final
          inspection before they are shipped to our customers. Our quality
          control team checks each product to ensure that it meets our high
          standards of quality.
        </label>
        <label className={classNames(styles.contentSubtitle, "mt-3")}>
          Our Commitment to Quality
        </label>
        <label className={classNames(styles.contentText)}>
          Silver Sky Apparel is committed to providing our customers with the
          highest quality sportswear and leisure apparel. We take pride in our
          work, and we are committed to delivering products that exceed our
          customers' expectations.
        </label>
        <label className={classNames(styles.contentText, "mt-1")}>
          We understand that our customers expect nothing less than the best,
          and we strive to meet and exceed their expectations every time. Our
          quality assurance process is designed to ensure that every product we
          produce is of the highest quality.
        </label>
        <label className={classNames(styles.contentSubtitle, "mt-3")}>
          Product Guarantee:
        </label>
        <label className={classNames(styles.contentText)}>
          We offer the very lowest prices for the best products, Which are also
          negotiable for bulk orders. We always stand a guarantee for our
          products and any article found inconsistent with the approved samples
          is replaced by us, as we believe in establishing lasting business
          relations, You will see we do nothing except what you want us to do.
          Desired quality, Timely delivery, Customization, and lenient pricing
          are our tools to remain with you.
        </label>
        <label className={classNames(styles.contenttitle, "my-3")}>
          Technology Available
        </label>
        <label className={classNames(styles.contentText)}>
          Silver Sky Apparel is the leading manufacturers of sports uniforms
          which uses latest and modern technology for customised printing of
          uniforms and jerseys.
        </label>
        <label className={classNames(styles.contentText, "mt-1")}>
          • Sublimation Printing – Silver Sky Apparel uses latest sublimation
          techniques to provide you world class uniforms. Your design is first
          printed on a roll of paper using the sublimation ink and then the
          printed paper and fabric are fused together under high temperature.
        </label>
        <label className={classNames(styles.contentText, "mt-1")}>
          • Screen Printing – Silver Sky Apparel offers you the best customized
          screen printing services for your team uniforms, school uniforms,
          fleece hoodies, jerseys, polo shirts and all other sort of sportswear.
        </label>
        <label className={classNames(styles.contentText, "mt-1")}>
          • Cut & Sew – We are able to meet all your requirements for cut & sew
          uniforms designing perfect patterns with reference to your artwork,
          picture or even rough sketch. Not only this, original patterns are
          also customised in your size.
        </label>
        <label className={classNames(styles.contentText, "mt-1")}>
          • Embroidery Applique – This technique is very effective when you want
          to add branding on your team uniforms. Be it multicolor logo or
          decorative designs, Silver Sky Apparel provides embroidery of your
          logo on team uniforms, school uniforms, jerseys and many more.
        </label>
        <label className={classNames(styles.contentText, "mt-1 ")}>
          • Customised Printing – Whatever your sports is, we do customised
          printing for all sort of sports uniforms.we create professional and
          unique attractive designs for your team members with custom name,
          numbers and sizes for every player.
        </label>
        <label className={classNames(styles.contentSubtitle, "mt-3")}>
          Hand made Wire Work on garments:
        </label>
        <label className={classNames(styles.contentText, "mt-1 mb-5")}>
          We offer hand-made copper wire work on garments, customizing designs
          with intricate and elegant details. Our skilled artisans use their
          years of experience to create wearable art that adds sophistication to
          any garment. From wedding dresses to everyday wear, we can bring your
          vision to life with custom designs that range from simple to intricate
          patterns. Our state-of-the-art technology allows us to create highly
          detailed designs on various fabrics, ensuring that each piece is of
          the highest quality.
        </label>
      </div>
    </>
  );
};

export default About;
