import axios from "axios";
import { BaseURL } from "./endpoints";
export const HTTP_CLIENT = axios.create({
  baseURL: BaseURL,
});
const setupAxios = () => {
  HTTP_CLIENT.interceptors.request.use(
    (config: any) => {
      return config;
    },
    (err) => Promise.reject(err)
  );
};
export const initialConfig = () => {
  setupAxios();
};

initialConfig();
