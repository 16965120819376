import { FeatureFashion, FeatureJackets, FeatureSoccer } from "assets";
import classNames from "classnames";
import { useNavigate } from "react-router";
import styles from "./style.module.scss";

const FeaturedCategories = () => {
  const navigate = useNavigate();
  return (
    <div className={classNames("w-100")}>
      <div className={classNames(styles.titleContainer)}>
        <label className={classNames(styles.title)}>Featured Categories</label>
      </div>

      <div
        className={classNames(
          "d-flex flex-column flex-md-row justify-content-center align-items-center gap-4 m-3"
        )}
      >
        <img
          src={FeatureJackets}
          className={classNames(styles.imgStyle)}
          onClick={() => {
            navigate("/product-category/CW/PJackets");
          }}
        />

        <img
          src={FeatureSoccer}
          className={classNames(styles.imgStyle)}
          onClick={() => {
            navigate("/product-category/SW/SU");
          }}
        />

        <img
          src={FeatureFashion}
          className={classNames(styles.imgStyle)}
          onClick={() => {
            navigate("/product-category/CW/Hoodies");
          }}
        />
      </div>
    </div>
  );
};

export default FeaturedCategories;
