import { LeftArrowIcon, RightArrowIcon } from "assets";
import classNames from "classnames";
import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "./style.scss";
interface SliderInterface {
  featured: {
    thumbnail: string;
  }[];
}
function CustomSlider(props: SliderInterface) {
  const { featured } = props;
  const [active, setActive] = useState(0);
  const sliderRef = useRef<any>(null);
  const left = useRef(0);

  const handleAfterChange = (index: number) => {
    setActive(index);
  };

  const settings = {
    afterChange: handleAfterChange,
    // autoplay: true,
    // infinite: true,
    // speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleLeft = () => {
    sliderRef.current.slickPrev();
  };
  const handleRight = () => {
    sliderRef.current.slickNext();
  };

  return (
    <div className="position-relative">
      <Slider {...settings} ref={sliderRef}>
        {featured.map((item, key) => {
          return (
            <div
              key={key}
              className={classNames("position-relative cover-preview")}
            >
              <img
                className={classNames("cover-preview-img")}
                src={item?.thumbnail}
                alt="cover-preview"
              />
            </div>
          );
        })}
      </Slider>

      <div
        className={classNames(
          "arrowsMainContainer d-flex justify-content-between"
        )}
      >
        <div
          className={classNames("position-relative arrowsSubContainer px-3")}
        >
          <div className={classNames("leftArrow")} onClick={handleLeft}>
            <LeftArrowIcon className={classNames("icon-arrow")} />
          </div>
          <div className={classNames("rightArrow")} onClick={handleRight}>
            <RightArrowIcon className={classNames("icon-arrow")} />
          </div>
        </div>
      </div>
      <div
        className={classNames(
          "dotsMainContainer d-flex justify-content-center gap-3"
        )}
      >
        {featured?.map((itm, key) => {
          return (
            <div
              key={key}
              role={"button"}
              className={classNames("dot", active === key && "highlighted-dot")}
              onClick={() => {
                setActive(key);
                sliderRef.current.slickGoTo(key);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

const propsAreEqual = (
  prevProps: Readonly<SliderInterface>,
  nextProps: Readonly<SliderInterface>
) => {
  return prevProps.featured === nextProps.featured;
};

export default React.memo(CustomSlider, propsAreEqual);
