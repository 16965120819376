import About from "pages/about";
import Contact from "pages/contact";
import Home from "pages/feed";
import Product from "pages/singleProduct";
import Products from "pages/products";
import { routeConstant } from "./routeConstant";
import ProductCategory from "pages/productCategory";
import ProductSubCategory from "pages/productSubCategory";
import Cart from "pages/cart";
import Checkout from "pages/checkout";

const publicRoute = [
  {
    path: routeConstant.home.path,
    title: routeConstant.home.title,
    Component: Home,
  },
  {
    path: routeConstant.products.path,
    title: routeConstant.products.title,
    Component: Products,
  },
  {
    path: routeConstant.about.path,
    title: routeConstant.about.title,
    Component: About,
  },
  {
    path: routeConstant.contact.path,
    title: routeConstant.contact.title,
    Component: Contact,
  },
  {
    path: routeConstant.product.path,
    title: routeConstant.product.title,
    Component: Product,
  },
  {
    path: routeConstant.productCategory.path,
    title: routeConstant.productCategory.title,
    Component: ProductCategory,
  },
  {
    path: routeConstant.productSubCategory.path,
    title: routeConstant.productSubCategory.title,
    Component: ProductSubCategory,
  },
  {
    path: routeConstant.cart.path,
    title: routeConstant.cart.title,
    Component: Cart,
  },
  {
    path: routeConstant.checkout.path,
    title: routeConstant.checkout.title,
    Component: Checkout,
  },
];

export { publicRoute, routeConstant };
