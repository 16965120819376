import classNames from "classnames";
import styles from "./style.module.scss";

interface InputProps extends React.HTMLProps<HTMLTextAreaElement> {
  label: string;
  labelStyle: any;
  padding: any;
}

const CustomTextArea = ({
  label,
  required,
  value,
  placeholder,
  onChange,
  labelStyle,
  padding,
  disabled,
  rows,
}: Partial<InputProps>) => {
  return (
    <div className={classNames("position-relative w-100")}>
      <div className={classNames("d-flex flex-column")}>
        <label
          className={`${styles.inputLabel} ${labelStyle ? labelStyle : ""}`}
        >
          {label} {!!required && <label className={styles.asterik}>*</label>}
        </label>
        <div className={`${styles.inputContainer}`}>
          <textarea
            rows={rows}
            disabled={disabled}
            placeholder={placeholder}
            className={`${styles.inputStyle}`}
            value={value}
            onChange={onChange}
            style={padding ? { padding: padding } : {}}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomTextArea;
