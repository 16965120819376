import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "shared/redux/store";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebvitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
ReactGA.initialize("G-4Q9GBT3NCE");
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/* <React.StrictMode> */}
        <App />
        {/* </React.StrictMode> */}
      </PersistGate>
    </Provider>
  </BrowserRouter>
);
const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};
reportWebVitals(SendAnalytics);
