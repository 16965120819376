export const routeConstant = {
  home: {
    path: "/",
    title: "Home",
  },
  about: {
    path: "/about",
    title: "About",
  },
  contact: {
    path: "/contact",
    title: "Contact",
  },
  products: {
    path: "/products",
    title: "Products",
  },
  product: {
    path: "/product/:id",
    title: "Product",
  },
  productCategory: {
    path: "/product-category/:name",
    title: "Product",
  },
  productSubCategory: {
    path: "/product-category/:name/:subCateg",
    title: "Product",
  },
  cart: {
    path: "/cart",
    title: "Cart",
  },
  checkout: {
    path: "/checkout",
    title: "Checkout",
  },
};
