import classNames from "classnames";
import { Archive } from "react-feather";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CartProduct from "shared/components/cartProduct";
import CustomButton from "shared/components/customButton";
import { routeConstant } from "shared/routes/routeConstant";
import styles from "./style.module.scss";

const Cart = () => {
  const { orders } = useSelector((state: any) => state.root);
  const navigate = useNavigate();
  return (
    <div className={classNames(styles.customContainer, "d-flex  p-4")}>
      {orders?.orders?.length > 0 ? (
        <div
          className={classNames(
            styles.emptyContainer,
            "py-5 gap-4 flex-column flex-lg-row"
          )}
        >
          <div
            className={classNames(
              "col-lg-8 col-12 d-flex flex-column  align-items-start  py-4",
              styles.productsContainer
            )}
          >
            <div className={classNames("d-flex align-items-start col-12 ")}>
              <div className={classNames("col-4")}></div>
              <div
                className={classNames(
                  "col-4 d-flex align-items-center justify-content-start"
                )}
              >
                <label className={classNames(styles.titleLabel)}>Product</label>
              </div>
              <div
                className={classNames(
                  "col-4 d-flex align-items-center justify-content-start"
                )}
              >
                <label className={classNames(styles.titleLabel)}>
                  Quantity
                </label>
              </div>
            </div>
            <div className={classNames("px-3 col-12 d-flex flex-column")}>
              {orders?.orders?.map((item: any, inx: any) => {
                return <CartProduct item={item} key={inx} />;
              })}
            </div>
          </div>
          <div
            className={classNames(
              "col-lg-4 col-12 d-flex flex-column  align-items-start  py-4 px-3",
              styles.productsContainer
            )}
          >
            <label className={classNames(styles.titleLabel)}>Cart Totals</label>
            <CustomButton
              title="Proceed To Submit Your RFQ"
              containerStyle={classNames(styles.proccedBtn, "mt-5")}
              onClick={() => {
                navigate(routeConstant.checkout.path);
              }}
            />
          </div>
        </div>
      ) : (
        <div
          className={classNames(
            styles.emptyContainer,
            "d-flex flex-column py-5"
          )}
        >
          <div className={classNames(styles.emptyLabelContainer, "mt-5 py-3")}>
            <Archive height={"16px"} width={"16px"} color="black" />{" "}
            <label className={classNames(styles.emptyLabel)}>
              Your cart is currently empty.
            </label>
          </div>
          <CustomButton
            title="Return to Shop"
            containerStyle={classNames(styles.btn, "mt-3 mb-5")}
            onClick={() => {
              navigate(routeConstant.products.path);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Cart;
