import { LogoIcon } from "assets";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { ChevronDown, ChevronUp, X } from "react-feather";
import { useLocation, useNavigate } from "react-router";
import { routeConstant } from "shared/routes/routeConstant";
import { categories } from "shared/utils/constant";
import styles from "./style.module.scss";

interface SideCanvasProps {
  setIsOpen: (val: boolean) => void;
  isOpen: boolean;
  loading: boolean;
}

const SideCanvas = ({ isOpen, setIsOpen, loading }: SideCanvasProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<string>(location?.pathname);
  const [displaySubTabs, setDisplaySubTabs] = useState<boolean>(false);
  function handleClick(e: any) {
    const elem: any = document.getElementById("sideCanvas");
    if (!elem.contains(e.target)) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    let elem: any = document.getElementById("backDropContainer");
    elem.addEventListener("click", (event: any) => {
      handleClick(event);
    });
    // eslint-disable-next-line
  }, [location?.pathname]);
  useEffect(() => {
    if (location.pathname.includes("about")) {
      setActiveTab(routeConstant.about.title);
    } else if (location.pathname.includes("product")) {
      setActiveTab(routeConstant.products.title);
    } else if (location.pathname.includes("contact")) {
      setActiveTab(routeConstant.contact.title);
    } else if (location.pathname.includes("")) {
      setActiveTab(routeConstant.home.title);
    }
  }, [location.pathname]);
  return (
    <div
      className={classNames(styles.backDropContainer, "d-lg-none")}
      style={isOpen ? { visibility: "visible" } : { visibility: "hidden" }}
      id="backDropContainer"
    >
      <div
        className={classNames(
          styles.mainContainer,
          isOpen ? styles.shown : styles.hidden
        )}
        id="sideCanvas"
      >
        <div
          className={`${"justify-content-between p-4"}  ${
            styles.logoContainer
          } d-flex align-items-center w-100 p-0`}
        >
          <LogoIcon
            role={"button"}
            onClick={() => {
              setIsOpen(false);
              navigate(routeConstant.home.path);
              setActiveTab(routeConstant.home.path);
            }}
          />

          <X
            role={"button"}
            color="white"
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </div>
        <div
          className={classNames(
            "d-flex flex-column justify-content-center align-items-center gap-4 mt-3"
          )}
        >
          <label
            className={classNames(
              styles.navlabel,
              activeTab === routeConstant?.home.title && styles.activeNav
            )}
            onClick={() => {
              setIsOpen(false);
              setActiveTab(routeConstant.home.title);
              navigate(routeConstant.home.path);
            }}
          >
            Home
          </label>

          <label
            className={classNames(
              styles.navlabel,
              activeTab === routeConstant?.products.title && styles.activeNav
            )}
            onClick={() => {
              setActiveTab(routeConstant.products.title);
              setDisplaySubTabs(!displaySubTabs);
            }}
          >
            Products
            {displaySubTabs ? (
              <ChevronUp height={22} width={22} />
            ) : (
              <ChevronDown height={22} width={22} />
            )}
          </label>

          {displaySubTabs && <DropDown setIsOpen={setIsOpen} />}

          <label
            className={classNames(
              styles.navlabel,
              activeTab === routeConstant?.about.title && styles.activeNav
            )}
            onClick={() => {
              setIsOpen(false);
              setActiveTab(routeConstant.about.title);
              navigate(routeConstant.about.path);
            }}
          >
            About
          </label>
          <label
            className={classNames(
              styles.navlabel,
              activeTab === routeConstant?.contact.title && styles.activeNav
            )}
            onClick={() => {
              setIsOpen(false);
              setActiveTab(routeConstant.contact.title);
              navigate(routeConstant.contact.path);
            }}
          >
            Contact
          </label>
        </div>
      </div>
    </div>
  );
};

const DropDown = ({ setIsOpen }: any) => {
  const [activeSubTab, setActiveSubTab] = useState<string>("");
  const navigate = useNavigate();
  return (
    <>
      {categories.map((categ, ind) => {
        return (
          <>
            <label
              className={classNames(
                styles.navlabel,
                activeSubTab === categ.name && styles.activeNav
              )}
              onClick={() => {
                if (categ.name === activeSubTab) {
                  setActiveSubTab("");
                } else {
                  setActiveSubTab(categ.name);
                }
              }}
              key={ind}
            >
              {categ.name}
              {activeSubTab === categ.name ? (
                <ChevronUp height={22} width={22} />
              ) : (
                <ChevronDown height={22} width={22} />
              )}
            </label>
            {activeSubTab === categ.name &&
              categ.subCategories.map((subCat, subInd) => {
                return (
                  <label
                    className={classNames(styles.navlabel)}
                    onClick={() => {
                      navigate(subCat?.route);
                      setIsOpen(false);
                    }}
                  >
                    {subCat.name}
                  </label>
                );
              })}
          </>
        );
      })}
    </>
  );
};

export default SideCanvas;
