import classNames from "classnames";
import styles from "./style.module.scss";

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  label: string;
  required: boolean;
  password: boolean;
  setShowPassword: (val: boolean) => void | any;
  error: string;
  showPassword: boolean;
  isDate: boolean;
  labelStyle: any;
  padding: any;
}

const CustomInput = ({
  label,
  required,
  error,
  value,
  placeholder,
  type,
  onChange,
  labelStyle,
  padding,
  disabled,
}: Partial<InputProps>) => {
  return (
    <div className={classNames("position-relative w-100")}>
      <div className={classNames("d-flex flex-column")}>
        <label
          className={`${styles.inputLabel} ${labelStyle ? labelStyle : ""}`}
        >
          {label} {!!required && <label className={styles.asterik}>*</label>}
        </label>
        <div className={`${styles.inputContainer}`}>
          <input
            disabled={disabled}
            type={type}
            placeholder={placeholder}
            className={`${styles.inputStyle}`}
            value={value}
            onChange={onChange}
            style={padding ? { padding: padding } : {}}
          />
        </div>
      </div>
      {!!error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default CustomInput;
