import { useEffect } from "react";
import NavWrapper from "shared/components/navWrapper";

interface LayoutProps {
  path: string;
  title: string;
  Component: any;
}

function Layout({ title, Component }: Partial<LayoutProps>) {
  useEffect(() => {
    document.title = title + " - Silver Sky Apparel";
  });

  return (
    <>
      <div style={{ minHeight: "100vh" }}>
        <NavWrapper>
          <Component />
        </NavWrapper>
      </div>
    </>
  );
}

export default Layout;
