import classNames from "classnames";
import { X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setOrders } from "shared/redux/reducers/orderSlice";
import { routeConstant } from "shared/routes/routeConstant";
import { CoverURL } from "shared/utils/endpoints";
import style from "./style.module.scss";

const ShoppingCartProduct = ({ item, setIsOpen, index }: any) => {
  const { orders } = useSelector((state: any) => state.root);
  const dipatch = useDispatch();
  const navigate = useNavigate();
  const handleRemove = () => {
    let filterOrders = orders?.orders.filter((itm: any) => {
      return itm?.product?._id !== item?._id;
    });
    dipatch(setOrders({ orders: filterOrders }));
  };
  return (
    <div
      className={classNames(
        "d-flex justify-content-between align-items-start mx-3 py-2",
        style.shopingCartTop,
        index > 0 ? "mt-4" : ""
      )}
      onClick={() => {
        setIsOpen(false);
        navigate(routeConstant.product.path.replace(":id", item?._id));
      }}
      role="button"
    >
      <img
        src={item?.cover ? CoverURL + item?.cover : ""}
        className={classNames(style.imgStyle)}
      />
      <label role="button" className={classNames(style.title)}>
        {item?.title}
      </label>
      <X
        role={"button"}
        color="black"
        onClick={(e) => {
          e.stopPropagation();
          handleRemove();
        }}
      />
    </div>
  );
};

export default ShoppingCartProduct;
