import classNames from "classnames";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  GetSingleProduct,
  GetSuggestion,
} from "shared/services/productServics";
import styles from "./style.module.scss";
import { CoverURL } from "shared/utils/endpoints";
import CustomButton from "shared/components/customButton";
import { Spinner } from "react-bootstrap";
import ProductCard from "shared/components/productCard";
import BoxLoader from "shared/components/loader";
import { returnCategoryInitial } from "shared/utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { setOrders } from "shared/redux/reducers/orderSlice";
import { toastMessage } from "shared/components/toast";

const Product = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dipatch = useDispatch();
  const { orders } = useSelector((state: any) => state.root);
  const [loading, setLoading] = useState<boolean>(false);
  const [product, setProduct] = useState<any>([]);
  const [loading2, setLoading2] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<any>([]);
  const [quantity, setQuantity] = useState<number>(1);
  const [backgroundPosition, setBackgroundPosition] = useState<any>("0% 0%");
  const [backgroundImage, setBackgroundImage] = useState<any>(null);

  const getProductDetails = () => {
    GetSingleProduct(id)
      .then(({ data }) => {
        setProduct(data);
        setLoading2(true);
        getSugesstions(data?.categories);
        setBackgroundImage(`url(${CoverURL + data?.cover})`);
        document.title = data?.title + " - Silver Sky Apparel";
      })
      .catch((err) => {
        console.log("ERR", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSugesstions = (categ: string) => {
    GetSuggestion(id, categ, 4)
      .then(({ data }) => {
        setSuggestions(data);
      })
      .catch((err) => {
        console.log("ERR", err);
      })
      .finally(() => {
        setLoading2(false);
      });
  };

  const handleMouseMove = (e: any) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setBackgroundPosition(`${x}% ${y}%`);
  };

  useEffect(() => {
    let elem: any = document.getElementById("singleProd");
    // @ts-ignore
    elem.scrollTop = 0;
  }, [id]);

  const handleAdd = () => {
    if (Number(quantity) > 0) {
      let filterOrders = orders?.orders.filter((itm: any) => {
        return itm?.product?._id !== product?._id;
      });
      let foundItem: any = orders?.orders.filter((itm: any) => {
        return itm?.product?._id === product?._id;
      });
      if (foundItem?.length > 0) {
        let qnt = Number(foundItem[0]?.quantity) + Number(quantity);
        let resp = {
          product: product,
          quantity: qnt,
        };
        filterOrders.push(resp);
      } else {
        let resp = {
          product: product,
          quantity: quantity,
        };
        filterOrders.push(resp);
      }
      dipatch(setOrders({ orders: filterOrders }));
      toastMessage("success", "Added to Cart!");
    }
  };

  useEffect(() => {
    setLoading(true);
    getProductDetails();
  }, [id]);
  return (
    <div
      className={classNames(styles.customContainer, "d-flex flex-column")}
      id="singleProd"
    >
      <div className={classNames("mt-5 d-flex align-self-start px-3 p-sm-0")}>
        {loading ? (
          <BoxLoader iconStyle={classNames(styles.breadCrumpLoader)} />
        ) : (
          <>
            <label className={classNames(styles.breadCrump)} role="button">
              Home
            </label>
            <label className={classNames(styles.breadCrump, "mx-1")}> / </label>
            <label
              className={classNames(styles.breadCrump)}
              role="button"
              onClick={() => {
                if (product?.categories === "Casual Wear") {
                  navigate("/product-category/CW");
                } else if (product?.categories === "Sports Wear") {
                  navigate("/product-category/SW");
                } else {
                  navigate("/product-category/CPW");
                }
              }}
            >
              {product?.categories}
            </label>
            <label className={classNames(styles.breadCrump, "mx-1")}> / </label>
            <label
              className={classNames(styles.breadCrump)}
              role="button"
              onClick={() => {
                let initial = returnCategoryInitial(product?.subCategory);
                if (product?.categories === "Casual Wear") {
                  navigate(`/product-category/CW/${initial}`);
                } else if (product?.categories === "Sports Wear") {
                  navigate(`/product-category/SW/${initial}`);
                } else {
                  navigate(`/product-category/CPW/${initial}`);
                }
              }}
            >
              {product?.subCategory}
            </label>
            <label className={classNames(styles.breadCrump, "mx-1")}> / </label>
            <label className={classNames(styles.breadCrump)}>
              {product?.title}
            </label>
          </>
        )}
      </div>
      <div className={classNames("row d-flex m-0 my-3 px-3 p-sm-0")}>
        <div className={classNames("col-lg-5 col-12 px-0")}>
          {loading ? (
            <BoxLoader iconStyle={classNames(styles.imgLoader)} />
          ) : (
            <figure
              onMouseMove={handleMouseMove}
              style={{
                backgroundImage: backgroundImage,
                backgroundPosition: backgroundPosition,
              }}
            >
              <img
                className={classNames(styles.imgZ)}
                src={product?.cover ? CoverURL + product?.cover : ""}
              />
            </figure>
          )}
        </div>
        <div
          className={classNames(
            "col-lg-7 col-12 d-flex flex-column align-items-start px-0 px-lg-4 pt-lg-0 pt-3"
          )}
        >
          <div className="d-flex align-items-center">
            {loading ? (
              <BoxLoader
                iconStyle={classNames(styles.categoryLoader, "ms-1")}
              />
            ) : (
              <>
                <label className={classNames(styles.categoryTitle)}>
                  Categories :
                </label>
                <label
                  className={classNames(styles.category, "ms-1")}
                  role={"button"}
                >
                  {product?.categories}
                </label>
                <label className={classNames(styles.category)} role={"button"}>
                  , {product?.subCategory}
                </label>
              </>
            )}
          </div>
          {loading ? (
            <BoxLoader iconStyle={classNames(styles.titleLoader, "my-4")} />
          ) : (
            <label className={classNames(styles.title, "py-4")}>
              {product?.title}
            </label>
          )}

          {loading ? (
            <div className={classNames("d-flex")}>
              <BoxLoader iconStyle={classNames(styles.numFieldLoader)} />
              <BoxLoader
                iconStyle={classNames(styles.btnContainerLoader, "ms-3")}
              />
            </div>
          ) : (
            <div className={classNames("d-flex")}>
              <input
                type={"number"}
                className={classNames(styles.numField)}
                defaultValue={1}
                value={quantity}
                onChange={(e: any) => {
                  setQuantity(e.target.value);
                }}
              />
              <CustomButton
                title="Add to quote"
                onClick={handleAdd}
                containerStyle={classNames(styles.btnContainer, "ms-3")}
              />
            </div>
          )}
        </div>
      </div>
      <label className={classNames(styles.sugestLabel, "mt-5 mb-3")}>
        You Might also Love
      </label>
      <div className={classNames("px-3 mt-4")}>
        <div
          className={`d-flex row justify-content-sm-start  justify-content-center   align-items-center`}
        >
          {loading2 ? (
            <div
              className={classNames(
                "mb-4 d-flex justify-content-center align-items-center",
                styles.minHeight
              )}
            >
              <Spinner animation="border" />
            </div>
          ) : (
            <>
              {suggestions?.map((item: any, inx: any) => {
                return <ProductCard key={inx} item={item} isInProduct />;
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Product;
