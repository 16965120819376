import classNames from "classnames";
import { Formik } from "formik";
import { useState } from "react";
import { Archive } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CustomButton from "shared/components/customButton";
import CustomInput from "shared/components/customInput";
import CustomTextArea from "shared/components/customTextArea";
import { toastMessage } from "shared/components/toast";
import { resetOrders } from "shared/redux/reducers/orderSlice";
import { routeConstant } from "shared/routes/routeConstant";
import { Addorder } from "shared/services/orderService";
import { OrderVS } from "shared/utils/validations";
import styles from "./style.module.scss";
const Checkout = () => {
  const { orders } = useSelector((state: any) => state.root);
  const [notes, setNotes] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialValues = {
    firstname: "",
    lastname: "",
    phonenumber: "",
    email: "",
  };

  const handleRequest = (values: any, action: any) => {
    let obj = {
      orders: orders?.orders,
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      phonenumber: values.phonenumber,
      sideNotes: notes,
    };
    Addorder(obj)
      .then(({ data }) => {
        dispatch(resetOrders());
        navigate(routeConstant.products.path);
        toastMessage("success", "Order Placed Successfully!");
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        action.setSubmitting(false);
      });
  };
  return (
    <div className={classNames(styles.customContainer, "d-flex  p-4")}>
      {orders?.orders?.length > 0 ? (
        <div
          className={classNames(
            styles.emptyContainer,
            "py-5 flex-column flex-lg-row justify-content-between gap-4"
          )}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={(values, action) => {
              action.setSubmitting(true);
              handleRequest(values, action);
            }}
            validationSchema={OrderVS}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <>
                <div
                  className={classNames(
                    "col-lg-7 col-12 d-flex flex-column  align-items-start gap-4"
                  )}
                >
                  <div
                    className={classNames(
                      styles.productsContainer,
                      "w-100 d-flex flex-column  align-items-start justify-content-start py-4 px-3 gap-4"
                    )}
                  >
                    <label className={classNames(styles.titleLabel)}>
                      Inquiry Form
                    </label>
                    <div
                      className={classNames(
                        "d-flex w-100 gap-4 flex-column flex-md-row"
                      )}
                    >
                      <CustomInput
                        label="First Name"
                        required
                        type="text"
                        placeholder="First Name"
                        error={
                          touched.firstname && errors.firstname
                            ? errors.firstname
                            : ""
                        }
                        onChange={handleChange("firstname")}
                        value={values.firstname}
                      />
                      <CustomInput
                        label="Last Name"
                        required
                        type="text"
                        placeholder="Last Name"
                        error={
                          touched.lastname && errors.lastname
                            ? errors.lastname
                            : ""
                        }
                        onChange={handleChange("lastname")}
                        value={values.lastname}
                      />
                    </div>
                    <CustomInput
                      label="Phone"
                      required
                      type="text"
                      placeholder="Phone"
                      error={
                        touched.phonenumber && errors.phonenumber
                          ? errors.phonenumber
                          : ""
                      }
                      onChange={handleChange("phonenumber")}
                      value={values.phonenumber}
                    />
                    <CustomInput
                      label="Email"
                      required
                      type="text"
                      placeholder="Email"
                      error={touched.email && errors.email ? errors.email : ""}
                      onChange={handleChange("email")}
                      value={values.email}
                    />
                  </div>
                  <div
                    className={classNames(
                      styles.productsContainer,
                      "w-100 d-flex flex-column  align-items-start justify-content-start py-4 px-3 gap-4"
                    )}
                  >
                    <CustomTextArea
                      label="Order Notes (optional)"
                      rows={2}
                      placeholder="Notes about your order, e.g.special notes for delivery"
                      onChange={(e: any) => {
                        setNotes(e.target.value);
                      }}
                      value={notes}
                    />
                  </div>
                </div>
                <div
                  className={classNames(
                    "col-lg-5 col-12 d-flex flex-column  align-items-start gap-4"
                  )}
                >
                  <div
                    className={classNames(
                      "d-flex flex-column  align-items-start  py-4 px-3 gap-4 w-100",
                      styles.productsContainer
                    )}
                  >
                    <label className={classNames(styles.titleLabel)}>
                      Your Order
                    </label>

                    <label className={classNames(styles.productLabel)}>
                      Product
                    </label>
                    <div className={classNames("d-flex flex-column gap-3")}>
                      {orders?.orders?.map((item: any, inx: any) => {
                        return (
                          <div
                            key={inx}
                            className={classNames(
                              "d-flex align-items-center gap-3"
                            )}
                          >
                            <label className={classNames(styles.pTitle)}>
                              {item?.product?.title}
                            </label>
                            <label className={classNames(styles.pSubTitle)}>
                              X
                            </label>
                            <label className={classNames(styles.pTitle)}>
                              {item?.quantity}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    className={classNames(
                      "d-flex flex-column  align-items-start  py-4 px-3 gap-4 w-100",
                      styles.productsContainer
                    )}
                  >
                    <label className={classNames(styles.reqLabel)}>
                      Request For Quote
                    </label>
                    <label className={classNames(styles.infoLabel)}>
                      Your personal data will be used to process your order.
                    </label>
                    <CustomButton
                      title="Submit Your Request for Quote"
                      onClick={() => {
                        handleSubmit();
                      }}
                      containerStyle={classNames(styles.prooceedBtn)}
                      loading={isSubmitting}
                      isDisable={isSubmitting}
                    />
                  </div>
                </div>
              </>
            )}
          </Formik>
        </div>
      ) : (
        <div
          className={classNames(
            styles.emptyContainer,
            "d-flex flex-column py-5"
          )}
        >
          <div className={classNames(styles.emptyLabelContainer, "mt-5 py-3")}>
            <Archive height={"16px"} width={"16px"} color="black" />{" "}
            <label className={classNames(styles.emptyLabel)}>
              Your cart is currently empty.
            </label>
          </div>
          <CustomButton
            title="Return to Shop"
            containerStyle={classNames(styles.btn, "mt-3 mb-5")}
            onClick={() => {
              navigate(routeConstant.products.path);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Checkout;
