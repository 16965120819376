import { CommingSoonAnimation } from "assets";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import Animation from "shared/components/animation";
import ProductCard from "shared/components/productCard";
import { routeConstant } from "shared/routes/routeConstant";
import { GetSubCategoryAllProducts } from "shared/services/productServics";
import { isValidPath, returnCategoryName } from "shared/utils/helper";
import styles from "./style.module.scss";

const ProductSubCategory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<any>([]);
  const [category, setCategory] = useState<any>("");
  const [subCategory, setSubCategory] = useState<any>("");
  const getProductDetails = (categ: string) => {
    let obj = {
      categ: categ,
    };
    console.log("Gg", categ);
    GetSubCategoryAllProducts(obj)
      .then(({ data }) => {
        setProducts(data);
        document.title = categ + " - Silver Sky Apparel";
      })
      .catch((err) => {
        console.log("ERR", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    let categ = location.pathname.split("/")[2];
    let subCateg = location.pathname.split("/")[3].replace("%20", " ");
    if (
      categ !== "CW" &&
      categ !== "CPW" &&
      categ !== "SW" &&
      categ !== "SBW"
    ) {
      navigate(routeConstant.home.path);
    } else if (isValidPath(subCateg, categ)) {
      setLoading(true);
      setSubCategory(returnCategoryName(subCateg));
      if (categ === "CW") {
        setCategory("Casual Wear");
      } else if (categ === "SW") {
        setCategory("Sports Wear");
      } else if (categ === "SBW") {
        setCategory("Sublimation Wear");
      } else {
        setCategory("Compression Wear");
      }
      getProductDetails(returnCategoryName(subCateg));
    } else {
      navigate(routeConstant.home.path);
    }
    // eslint-disable-next-line
  }, [location.pathname]);
  return (
    <div
      className={classNames(styles.customContainer, "d-flex flex-column")}
      id="singleProd"
    >
      <div className={classNames("mt-5 d-flex align-self-start px-3 p-sm-0")}>
        <>
          <label
            className={classNames(styles.breadCrump)}
            role="button"
            onClick={() => {
              navigate(routeConstant.home.path);
            }}
          >
            Home
          </label>
          <label className={classNames(styles.breadCrump, "mx-1")}> / </label>
          <label
            className={classNames(styles.breadCrump)}
            role="button"
            onClick={() => {
              if (category === "Casual Wear") {
                navigate("/product-category/CW");
              } else if (category === "Sports Wear") {
                navigate("/product-category/SW");
              } else {
                navigate("/product-category/CPW");
              }
            }}
          >
            {category}
          </label>
          <label className={classNames(styles.breadCrump, "mx-1")}> / </label>
          <label className={classNames(styles.breadCrump)}>{subCategory}</label>
        </>
      </div>
      <label className={classNames(styles.title, "py-5")}>{subCategory}</label>

      <div
        className={`d-flex row justify-content-sm-start  justify-content-center   align-items-center`}
      >
        {loading ? (
          <div
            className={classNames(
              "mb-4 d-flex justify-content-center align-items-center",
              styles.minHeight
            )}
          >
            <Spinner animation="border" />
          </div>
        ) : (
          <>
            {products?.length > 0 ? (
              <>
                <div className={classNames("d-flex mb-5 px-4 p-sm-0")}>
                  <label className={classNames(styles.breadCrump)}>
                    Showing all of {products?.length} results
                  </label>
                </div>
                {products?.map((item: any, inx: any) => {
                  return <ProductCard key={inx} item={item} />;
                })}
              </>
            ) : (
              <div
                className={classNames(
                  "d-flex align-items-center justify-content-center"
                )}
              >
                <div className={classNames(styles.animationContainer)}>
                  <Animation Pic={CommingSoonAnimation} />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ProductSubCategory;
