import classNames from "classnames";
import { useEffect } from "react";
import { X } from "react-feather";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { routeConstant } from "shared/routes/routeConstant";
import CustomButton from "../customButton";
import ShoppingCartProduct from "../shopingCartProduct";
import styles from "./style.module.scss";

interface SideCanvasProps {
  setIsOpen: (val: boolean) => void;
  isOpen: boolean;
}

const ShoppingCartCanvas = ({ isOpen, setIsOpen }: SideCanvasProps) => {
  const { orders } = useSelector((state: any) => state.root);
  const navigate = useNavigate();
  function handleClick(e: any) {
    const elem: any = document.getElementById("sideShopingCart");
    if (!elem.contains(e.target)) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    let elem: any = document.getElementById("backDropContainer2");
    elem.addEventListener("click", (event: any) => {
      handleClick(event);
    });
    // eslint-disable-next-line
  }, []);
  return (
    <div
      className={classNames(styles.backDropContainer)}
      style={isOpen ? { visibility: "visible" } : { visibility: "hidden" }}
      id="backDropContainer2"
    >
      <div
        className={classNames(
          styles.mainContainer,
          isOpen ? styles.shown : styles.hidden
        )}
        id="sideShopingCart"
      >
        <div
          className={`${"justify-content-between p-4"}  ${
            styles.logoContainer
          } d-flex align-items-center w-100 p-0`}
        >
          <label className={classNames(styles.title)}>Shoping Cart</label>

          <X
            role={"button"}
            color="black"
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </div>
        {orders?.orders?.length > 0 ? (
          <>
            {orders?.orders?.map((item: any, inx: any) => {
              return (
                <ShoppingCartProduct
                  item={item?.product}
                  key={inx}
                  setIsOpen={setIsOpen}
                  index={inx}
                />
              );
            })}
            <div className={classNames("mt-4 mx-3")}>
              <CustomButton
                title="View Cart"
                containerStyle={classNames(styles.btn1)}
                onClick={() => {
                  setIsOpen(false);
                  navigate(routeConstant.cart.path);
                }}
              />
              <CustomButton
                title="Checkout"
                containerStyle={classNames(styles.btn2, "mt-3")}
                onClick={() => {
                  setIsOpen(false);
                  navigate(routeConstant.checkout.path);
                }}
              />
            </div>
          </>
        ) : (
          <label>No Products in the cart</label>
        )}
      </div>
    </div>
  );
};

export default ShoppingCartCanvas;
