import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { ChevronLeft, ChevronRight } from "react-feather";
import { useNavigate } from "react-router";
import ProductCard from "shared/components/productCard";
import { routeConstant } from "shared/routes/routeConstant";
import { GetAllProducts } from "shared/services/productServics";
import styles from "./style.module.scss";

const Products = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<any>([]);
  const skip = useRef<any>(0);
  const [count, setCount] = useState<any>(0);
  const [page, setPage] = useState<any>(0);
  const [maxCount, setMaxCount] = useState<any>(0);
  const getProductDetails = () => {
    GetAllProducts(skip.current)
      .then(({ data }) => {
        setProducts(data[0]?.data);
        setCount(data[0]?.count);
        let res = data[0]?.data?.length % 16;
        if (res === 0) {
          setMaxCount((page + 1) * 16);
        } else {
          setMaxCount(page * 16 + res);
        }
      })
      .catch((err) => {
        console.log("ERR", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    skip.current = page * 16;
    getProductDetails();
  }, [page]);
  return (
    <div
      className={classNames(styles.customContainer, "d-flex flex-column")}
      id="singleProd"
    >
      <div className={classNames("mt-5 d-flex align-self-start px-3 p-sm-0")}>
        <>
          <label
            className={classNames(styles.breadCrump)}
            role="button"
            onClick={() => {
              navigate(routeConstant.home.path);
            }}
          >
            Home
          </label>
          <label className={classNames(styles.breadCrump, "mx-1")}> / </label>
          <label className={classNames(styles.breadCrump)}>Products</label>
        </>
      </div>
      <label className={classNames(styles.title, "py-5")}>Products</label>

      <div
        className={`d-flex row justify-content-sm-start  justify-content-center   align-items-center`}
      >
        {loading ? (
          <div
            className={classNames(
              "mb-4 d-flex justify-content-center align-items-center",
              styles.minHeight
            )}
          >
            <Spinner animation="border" />
          </div>
        ) : (
          <>
            <div className={classNames("d-flex mb-5 px-4 p-sm-0")}>
              <label className={classNames(styles.breadCrump)}>
                Showing {page * 16 + 1} - {maxCount} of {count} results
              </label>
            </div>
            {products?.map((item: any, inx: any) => {
              return <ProductCard key={inx} item={item} />;
            })}
            <div
              className={classNames(
                "d-flex justify-content-center align-items-center mb-5 mt-3"
              )}
            >
              {page !== 0 ? (
                <ChevronLeft
                  color="#f49d1a"
                  onClick={() => {
                    setPage(page - 1);
                  }}
                  role="button"
                />
              ) : null}

              {Array.from(Array(Math.ceil(count / 16)).keys()).map(
                (item, inx) => {
                  return (
                    <label
                      key={inx}
                      className={classNames(
                        styles.singlePageNumber,
                        inx === page && styles.singlePageActive
                      )}
                      onClick={() => {
                        setPage(inx);
                      }}
                    >
                      {inx + 1}
                    </label>
                  );
                }
              )}
              {page + 1 !== Math.ceil(count / 16) ? (
                <ChevronRight
                  color="#f49d1a"
                  onClick={() => {
                    setPage(page + 1);
                  }}
                  role="button"
                />
              ) : null}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Products;
